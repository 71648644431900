import React from 'react';
import {Body} from './Body';

const Home = () => {


  return (
    <div className="home">
      <Body />

    </div>
  )
};
export {Home};
