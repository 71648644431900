import React from 'react';

import siteLogo from './twoskystudiologo.png';
import logoBackground from './logo_bottom_background.png';
const SiteLogo = {
    logo: siteLogo,
    logoBackground: logoBackground
};

export {SiteLogo};
