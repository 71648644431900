import flatironPoppies from './art-pics/0B5A6DC9-2196-4967-827B-0DD45E991732.jpeg';
import drippingHair from './art-pics/dripping_hair.jpeg';
import shellLayersFullHorizontal from './art-pics/1A6A4948.JPG';
import shellLayersPartial1 from './art-pics/1A6A4955.JPG';
import shellLayersPartial2 from './art-pics/1A6A4962.JPG';
import orangeFishFull from './art-pics/orange_fish_full.jpeg';
import orangeFishCloseup1 from './art-pics/orange_fish_closeup1.jpeg';
import orangeFishCloseup2 from './art-pics/orange_fish_closeup2.jpeg';
import blueFishFull from './art-pics/blue_fish_full.jpeg';
import blueFishPartial from './art-pics/blue_fish_partial.jpeg';
import circleClumpTrees from './art-pics/circle_clump_trees.jpeg';
import clamDisco from './art-pics/clam_disco.jpeg';
import buddhaWithinFull from './art-pics/buddha_within_full.jpeg';
import buddhaWithinPartial from './art-pics/buddha_within_partial.jpeg';
import redPoppies from './art-pics/red_poppies.jpeg';
import redPoppiesPartial from './art-pics/red_poppies_partial.jpeg';
import lotsOLotus from './art-pics/lots_o_lotus.jpeg';
import lotusLifeCycle from './art-pics/lotus_life_cycle.jpeg';
import mossWaterPixels from './art-pics/moss_water_pixels.jpeg';
import mossWaterPixelsClose1 from './art-pics/moss_water_pixels_close1.jpeg';
import mossWaterPixelsClose2 from './art-pics/moss_water_pixels_close2.jpeg';
import mossWaterPixelsGallery from './art-pics/moss_water_pixels_gallery.jpeg';
import lotusHeartMountain from './art-pics/lotus_heart_mountain.jpeg';
import yellowUnderCanopy from './art-pics/yellow_under_canopy.jpeg';
import blueDanTien from './art-pics/blue_dan_tien.jpeg';
import heartacheDecompartmentalized from './art-pics/heartache_decompartmentalized.jpeg';
import tripleBlueEncausticPartial from './art-pics/triple_blue_encaustic_partial.jpeg';
import thornWrappedComfort from './art-pics/thorn_wrapped_comfort.jpeg';
import shipCthuluWaves from './art-pics/ship_cthulu_waves.jpeg';
import tripleBlueEncausticGallery from './art-pics/triple_blue_encaustic_gallery.jpeg';
import celloDan from './art-pics/cello_dan.jpeg';
import snailFreedom from './art-pics/snail_freedom.jpeg';
import manyBranches from './art-pics/many_branches.jpeg';
import celloDanPartial from './art-pics/cello_dan_partial.jpeg';
import drippingHairPartial from './art-pics/dripping_hair_partial.jpeg';
import fishyCubism from './art-pics/fishy_cubism.jpeg';
import samuraiDogJoy from './art-pics/samurai_dog_joy.jpeg';
import doubleDogPrint from './art-pics/double_dog_print.jpeg';
import bunniesRedSky from './art-pics/bunnies_red_sky.jpg';
import shellfishlyOrange from './art-pics/shellfishly_orange.jpg';
import octopusOverHeart from './art-pics/octopus_over_heart.jpg';
import personSittingInHeart from './art-pics/person_sitting_in_heart.jpg';
import squidRelationship from './art-pics/squid_relationship.jpg';
import bloodyStocking from './art-pics/bloody_stocking.jpg';
import cagedCrow from './art-pics/caged_crow.jpg';
import whereHaveYouBean from './art-pics/where_have_you_bean.jpg';
import youOctopi from './art-pics/you_octopi.jpg';
import youAreHereOrange from './art-pics/you_are_here_orange.jpg';
import acornInHeart from './art-pics/acorn_in_heart.jpg';
import cephalopod from './art-pics/cephalopod.jpg';
import gopherASnuggle from './art-pics/gopher_a_snuggle.png';
import whaleSky from './art-pics/whale_sky.png';
import whaleSkyBlue from './art-pics/whale_sky_blue.png';
import danceOfKelp from './art-pics/dance_of_kelp.jpeg';
import kenai from './art-pics/kenai.jpeg';
import jamiesLillies from './art-pics/lillies.jpg';
import shroomMoon from './art-pics/shroom_moon.jpeg';
import rooted from './art-pics/rooted.jpeg';

const ArtRepo = {
    encaustics: [
      {
        id: 201,
        src: drippingHair,
        title: "Hold Still and Find Out",
        medium: "Encaustic Paint, Clay, Straw",
        price: 5000,
        canvas: "Wood",
        year: 2021,
        partials: [
          { src: drippingHairPartial }
        ]
      },
      {
        id: 202,
        src: tripleBlueEncausticGallery,
        title: "Tree Reflections",
        medium: "Encaustic Paint",
        price: 5000,
        year: 2016,
        dimensions: `4x5"/piece`,
        partials: [
          { src: tripleBlueEncausticPartial }
        ]
      },
      {
        id: 203,
        src: manyBranches,
        title: "Angel Tree",
        medium: "Encaustic Wax",
        price: 5000,
        canvas: "Wood",
        year: 2017,
        dimensions: `8.5x8.5"`
      },
      {
        id: 204,
        src: danceOfKelp,
        title: "Dance of Kelp",
        medium: "Encaustic Wax, Clay",
        price: 5000,
        canvas: "Wood",
        year: 2020,
        dimensions: `8.5x15.5"`
      },
      {
        id: 205,
        src: cephalopod,
        title: "Octopus Garden",
        medium: "Encaustic paint, Shellac",
        price: 5000,
        canvas: "Wood",
        year: 2014
      },
      {
        id: 206,
        src: lotsOLotus,
        title: "Lotus Walks",
        medium: "Encaustic Wax, Chalk, Oil Paint, Spray Paint",
        price: 5000,
        canvas: "Wood",
        year: 2010,
        dimensions: `11x16"`
      },
      {
        id: 207,
        src: lotusLifeCycle,
        title: "Lotus Life Cycle",
        medium: "Encaustic Wax, Chalk, Oil Paint, Spray Paint",
        price: 5000,
        canvas: "Wood",
        year: 2010,
        dimensions: `11x16"`
      },
      {
        id: 208,
        src: shroomMoon,
        title: "Shroom Moon",
        medium: "Encaustic Wax, Clay, Water Color",
        price: 5000,
        canvas: "Wood",
        year: 2020,
        dimensions: `6x10"`
      },
      {
        id: 209,
        src: rooted,
        title: "Rooted",
        medium: "Encaustic Wax, Oil Paint, Clay",
        price: 5000,
        canvas: "Wood",
        year: 2020,
        dimensions: `9.5x14"`
      }
    ],
    prints: [
      {
        id: 101,
        src: whaleSky,
        title: "Whale Sky",
        medium: "Print",
        price: 5000,
        year: 1220,
        partials: [
          { src: whaleSkyBlue }
        ]
      },
      {
        id: 102,
        src: gopherASnuggle,
        title: "I Could Gopher A Snuggle!",
        medium: "Print",
        price: 5000,
        year: 1220
      },
      {
        id: 103,
        src: acornInHeart,
        title: "Acorn In Heart",
        medium: "Print",
        price: 5000,
        year: 1450
      },
      {
        id: 105,
        src: cagedCrow,
        title: "Caged Crow",
        medium: "Print",
        price: 5000,
        year: 1400
      },
      {
        id: 106,
        src: whereHaveYouBean,
        title: "Where Have You Bean?",
        medium: "Print",
        price: 5000,
        year: 820
      },
      {
        id: 107,
        src: youOctopi,
        title: "You Octopi My Heart",
        medium: "Print",
        price: 5000,
        year: 2220
      },
      {
        id: 108,
        src: youAreHereOrange,
        title: "You Are Here",
        medium: "Print",
        price: 5000,
        year: 1250
      },
      {
        id: 109,
        src: squidRelationship,
        title: "Squid Relationship",
        medium: "Print",
        price: 5000,
        year: 1120
      },
      {
        id: 110,
        src: bloodyStocking,
        title: "Bloody Stocking",
        medium: "Print",
        price: 5000,
        year: 720
      },
      {
        id: 111,
        src: personSittingInHeart,
        title: "Sitting In Heart",
        medium: "Print",
        price: 5000,
        year: 1370
      },
      {
        id: 112,
        src: octopusOverHeart,
        title: "Octopus Heart",
        medium: "Print",
        price: 5000,
        year: 1920
      },
      {
        id: 113,
        src: shellfishlyOrange,
        title: "Shellfishly Yours",
        medium: "Print",
        price: 5000,
        year: 1110
      },
      {
        id: 114,
        src: bunniesRedSky,
        title: "Bunnies Red Sky",
        medium: "Print",
        price: 5000,
        year: 1436
      },
      {
        id: 115,
        src: flatironPoppies,
        title: "Flatirons and Poppies",
        medium: "Encaustic Wax",
        price: 5000,
        canvas: "Wood",
        year: 2014,
        dimensions: `11x15"`
      },
      {
        id: 116,
        src: yellowUnderCanopy,
        title: "Ashley's Roots",
        medium: "Oil Pastels",
        price: 5000,
        canvas: "Paper",
        year: 2013,
        dimensions: `12x18"`
      },
      {
        id: 117,
        src: heartacheDecompartmentalized,
        title: "Fish Head",
        medium: "Print",
        price: 5000,
        year: 2009
      },
      {
        id: 118,
        src: thornWrappedComfort,
        title: "Thorn-wrapped Comfort",
        medium: "Print",
        price: 5000,
        year: 1620
      },
      {
        id: 119,
        src: shipCthuluWaves,
        title: "Ship Cthulu",
        medium: "Print",
        price: 5000,
        year: 1350
      },
      {
        id: 120,
        src: snailFreedom,
        title: "Victory Snail",
        medium: "Reduction Linoleum Print",
        price: 5000,
        canvas: "Paper",
        year: 2021,
        dimensions: `10x8.5"`
      },
      {
        id: 121,
        src: fishyCubism,
        title: "Swirly Swirly Fish Fish",
        medium: "Linoleum Print",
        price: 5000,
        canvas: "Paper",
        year: 2014
      },
      {
        id: 122,
        src: samuraiDogJoy,
        title: "ichibon",
        medium: "Linoleum Print",
        price: 5000,
        canvas: "Paper",
        year: 2005,
        dimensions: `5x8"`
      },
      {
        id: 123,
        src: kenai,
        title: "Kenai",
        medium: "linoleum print",
        price: 5000,
        canvas: "paper",
        year: 2005
      }
    ],
    mixed: [
      {
        id: 1,
        src: shellLayersFullHorizontal,
        title: "Shell Layers",
        medium: "Acrylic",
        price: 5000,
        year: 50,
        partials: [
          { src: shellLayersPartial1 },
          { src: shellLayersPartial2 }
        ]
      },
      {
        id: 2,
        src: orangeFishFull,
        title: "Orange Fish",
        medium: "Acrylic",
        price: 5000,
        year: 80,
        partials: [
          { src: orangeFishCloseup1 },
          { src: orangeFishCloseup2 }
        ]
      },
      {
        id: 3,
        src: blueFishFull,
        title: "Blue Fish",
        medium: "Acrylic",
        price: 5000,
        year: 100,
        partials: [
          {src: blueFishPartial}
        ]
      },
      {
        id: 4,
        src: circleClumpTrees,
        title: "Circle Clump Trees",
        medium: "Acrylic and Discs",
        price: 5000,
        year: 120
      },
      {
        id: 5,
        src: clamDisco,
        title: "Clam Disco",
        medium: "Acrylic",
        price: 5000,
        year: 75
      },
      {
        id: 6,
        src: buddhaWithinFull,
        title: "Buddha Within",
        medium: "Acrylic",
        price: 5000,
        year: 150,
        partials: [
          { src: buddhaWithinPartial }
        ]
      },
      {
        id: 7,
        src: redPoppies,
        title: "Poppies Reaching",
        medium: "Encaustic Wax",
        price: 5000,
        canvas: "Wood",
        year: 2017,
        dimensions: `11x16"`,
        partials: [
          { src: redPoppiesPartial }
        ]
      },
      {
        id: 11,
        src: mossWaterPixels,
        title: "Moss & Water Pixels",
        medium: "Acrylic",
        price: 5000,
        year: 320,
        partials: [
          { src: mossWaterPixelsClose1 },
          { src: mossWaterPixelsClose2 },
          { src: mossWaterPixelsGallery }
        ]
      },
      {
        id: 12,
        src: lotusHeartMountain,
        title: "Golden Lotus",
        medium: "Chalk, Encaustic Wax, Oil Paint, Spray Paint",
        price: 5000,
        canvas: "Wood",
        year: 2016,
        dimensions: `11x15"`
      },
      {
        id: 13,
        src: blueDanTien,
        title: "Blue Girl",
        medium: "Encaustic",
        price: 5000,
        canvas: "Wood",
        year: 2017,
        dimensions: `5x8"`
      },
      {
        id: 14,
        src: celloDan,
        title: "Dan and Cello",
        medium: "Oil Paint",
        price: 5000,
        canvas: "Canvas",
        year: 2013,
        dimensions: `14x20"`
      },
      {
        id: 15,
        src: jamiesLillies,
        title: "Jamie's Lillies",
        medium: "Oil Pastels",
        price: 5000,
        canvas: "Paper",
        year: 2010,
        dimensions: `12x18"`
      }
    ]
  };

export {ArtRepo};
